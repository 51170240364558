.App {
  font-family: "Roboto", sans-serif !important;
  background: url("./assets/background.jpg") no-repeat center center fixed;
  background-size: cover;
  min-height: 100vh;
  background-color: rgba(255, 255, 255, 0.1);
  background-blend-mode: lighten;
  margin: 0;
}

.logo {
  max-width: 150px; /* Tamaño más pequeño */
  display: block;
  margin: 0 auto;
}

header {
  min-height: 80px; /* Ajuste según sea necesario */
}

/* Títulos y descripción */
.main-title {
  font-size: 3rem;
  color: #333;
  position: relative;
  padding-bottom: 10px;
  padding-top: 10px;
}

.main-title::after {
  content: "";
  width: 350px;
  height: 3px;
  background-color: #502b16;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.main-description {
  text-align: center;
  font-size: 1.4rem;
  color: #555;
  max-width: 700px;
  margin: 0px auto;
  line-height: 1.8;
  padding-left: 8px;
  padding-right: 8px;
}

.description {
  margin-bottom: 30px;
}

/* WhatsApp Button */
.whatsapp-button {
  display: inline-flex;
  align-items: center;
  gap: 10px;
  font-size: 2.2rem;
  padding: 15px 30px; /* Más grande y espaciado */
}

.whatsapp-button:hover {
  background-color: #28a745;
  border-color: #28a745;
}

/* Redes Sociales */
.social-media a {
  color: #000;
  transition: color 0.3s;
}

.social-media a:hover {
  color: #007bff;
}

.gallery-container {
  margin: 0 auto;
}

.gallery-section h3 {
  font-size: 2.2rem;
  margin-bottom: 2rem;
  color: #333;
}

.react-photo-gallery--gallery > div {
  margin: 4px;
}

/* Mensaje de contacto */
.contact-message {
  font-size: 2rem;
  color: #333;
  margin-bottom: 10px; /* Espaciado entre mensaje y número */
  font-weight: 600;
}

/* Alineación del ícono de teléfono y número */
.contact-info {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 10px; /* Espaciado entre el ícono y el número */
  font-size: 1.8rem; /* Tamaño del texto del número */
  color: #333;
}

/* Ícono de teléfono */
.phone-icon {
  font-size: 1.8rem; /* Tamaño del ícono */
  color: #333;
}

@media (max-width: 768px) {
  .main-title {
    font-size: 2.2rem;
  }

  .main-description {
    font-size: 1.2rem;
  }

  .whatsapp-button {
    font-size: 1.5rem; /* Tamaño de texto más pequeño */
    padding: 10px 20px; /* Reducir el padding */
  }

  .whatsapp-button .fa-whatsapp {
    font-size: 32px; /* Hacer el icono más pequeño */
  }
}
